/* css-side-effects-plugin-ns:app/styles/global.css */
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
::before,
::after {
  --tw-content: "";
}
html,
:host {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family:
    ui-sans-serif,
    system-ui,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  line-height: inherit;
}
hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}
abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp,
pre {
  font-family:
    ui-monospace,
    SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    "Liberation Mono",
    "Courier New",
    monospace;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}
button,
select {
  text-transform: none;
}
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}
:-moz-focusring {
  outline: auto;
}
:-moz-ui-invalid {
  box-shadow: none;
}
progress {
  vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
  padding: 0;
}
legend {
  padding: 0;
}
ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
dialog {
  padding: 0;
}
textarea {
  resize: vertical;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}
button,
[role=button] {
  cursor: pointer;
}
:disabled {
  cursor: default;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}
img,
video {
  max-width: 100%;
  height: auto;
}
[hidden] {
  display: none;
}
*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}
.w-1\/4 {
  width: 25%;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.gap-20 {
  gap: 5rem;
}
.gap-4 {
  gap: 1rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.border {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.uppercase {
  text-transform: uppercase;
}
.italic {
  font-style: italic;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
@font-face {
  font-family: "DroidSansMono";
  src: url(/fonts/DroidSansMono/DroidSansMono.eot);
  src:
    local("\263a"),
    url(/fonts/DroidSansMono/DroidSansMono.woff) format("woff"),
    url(/fonts/DroidSansMono/DroidSansMono.ttf) format("truetype"),
    url(/fonts/DroidSansMono/DroidSansMono.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Futura-LT-Heavy";
  src: url(/fonts/Futura-LT-Heavy/Futura-LT-Heavy.eot);
  src:
    local("\263a"),
    url(/fonts/Futura-LT-Heavy/Futura-LT-Heavy.woff) format("woff"),
    url(/fonts/Futura-LT-Heavy/Futura-LT-Heavy.ttf) format("truetype"),
    url(/fonts/Futura-LT-Heavy/Futura-LT-Heavy.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "OswaldRegular";
  src: url(/fonts/OswaldRegular/OswaldRegular.eot);
  src:
    url(/fonts/OswaldRegular/OswaldRegular.eot) format("embedded-opentype"),
    url(/fonts/OswaldRegular/OswaldRegular.woff) format("woff"),
    url(/fonts/OswaldRegular/OswaldRegular.ttf) format("truetype"),
    url(/fonts/OswaldRegular/OswaldRegular.svg#OswaldRegular) format("svg");
}
@font-face {
  font-family: "OswaldLight";
  src: url(/fonts/OswaldLight/OswaldLight.eot);
  src:
    url(/fonts/OswaldLight/OswaldLight.eot) format("embedded-opentype"),
    url(/fonts/OswaldLight/OswaldLight.woff) format("woff"),
    url(/fonts/OswaldLight/OswaldLight.ttf) format("truetype"),
    url(/fonts/OswaldLight/OswaldLight.svg#OswaldLight) format("svg");
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
div {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
input[type=text],
input[type=password],
input[type=submit],
textarea,
button {
  -webkit-appearence: none;
  -moz-appearence: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
input[type=text]::-ms-clear {
  display: none;
}
table {
  border-collapse: collapse;
}
table thead {
  display: table-header-group;
}
table tbody {
  display: table-row-group;
}
body {
  padding: 25px 10px;
}
.live {
  margin: 0 auto;
  max-width: 940px;
}
.tournaments {
}
.tournament {
  background-color: #1b1d22;
  border-color: transparent;
  border-radius: 20px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  display: none;
  margin-bottom: 25px;
  padding: 15px;
  text-align: center;
}
.tournament_selected {
  border-color: #30ad63;
}
.tournament_name {
  color: #fff;
  cursor: pointer;
  font-family: OswaldRegular;
  font-size: 39px;
  line-height: 1.3;
  text-transform: uppercase;
}
.tournament_location {
  color: #b7b7b7;
  cursor: pointer;
  font-family: OswaldRegular;
  font-size: 25px;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}
.tournament_dates {
  color: #7c7c7c;
  cursor: pointer;
  font-family: OswaldLight;
  font-size: 19px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}
.tournament_time {
  background-color: #000;
  border-radius: 10px;
  display: none;
  margin: 0 auto;
  padding: 2px 0 10px;
  text-align: center;
  width: 144px;
}
.tournament_time_time {
  color: #fff;
  font-family: DroidSansMono;
  font-size: 31px;
  letter-spacing: -3px;
  line-height: 1.4;
}
.tournament_time_label {
  color: #30ad63;
  display: inline-block;
  font-family: OswaldRegular;
  font-size: 15px;
  line-height: 1;
  padding-left: 5px;
  text-transform: uppercase;
}
.matches {
  padding: 0 5px;
  text-align: center;
}
.match {
}
.match_info {
  background-color: #101011;
  border-radius: 8px;
  position: relative;
  text-align: left;
}
.match_round {
  color: #fff;
  display: inline-block;
  font-family: OswaldRegular;
  font-size: 25px;
  line-height: 36px;
  margin-left: 1.2%;
  vertical-align: middle;
}
.match_court {
  color: #b7b7b7;
  display: inline-block;
  font-family: OswaldRegular;
  font-size: 21px;
  line-height: 36px;
  vertical-align: middle;
  margin-left: 1.2%;
}
.match_status {
  color: #30ad63;
  display: inline-block;
  font-family: OswaldRegular;
  font-size: 21px;
  line-height: 36px;
  vertical-align: middle;
  margin-left: 1.2%;
}
.scores {
  background-color: #1b1d22;
  border-radius: 20px;
  display: block;
  margin: 30px auto;
  min-width: 640px;
  padding: 12px 8px;
  position: relative;
  width: 100%;
}
.scores_player {
  background-color: #515561;
  border-radius: 12px;
  height: 57px;
  position: relative;
}
.scores_player_1 {
  margin-top: 8px;
}
.scores_player_2 {
  margin-top: 9px;
}
.scores_player_border {
  border: 3px solid #ebebeb;
  border-radius: 12px;
  height: 57px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.scores_country {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 40px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 80px;
}
.scores_player_info {
  padding-left: 104px;
  position: absolute;
  text-align: left;
  top: 0;
  white-space: nowrap;
  width: 54%;
}
.scores_name {
  color: #fff;
  display: inline-block;
  font-family: OswaldRegular;
  font-size: 30px;
  text-transform: uppercase;
  line-height: 57px;
  vertical-align: middle;
}
.scores_seeding {
  color: #fff;
  display: inline-block;
  font-family: OswaldRegular;
  font-size: 30px;
  line-height: 57px;
  padding-left: 8px;
  vertical-align: middle;
}
.scores_games_back {
  border-left-color: rgba(0, 0, 0, .25);
  border-left-style: solid;
  border-left-width: 7px;
  border-right-color: rgba(0, 0, 0, .25);
  border-right-style: solid;
  border-right-width: 7px;
  height: 57px;
  right: 37%;
  position: absolute;
  top: 0;
  width: 7.8%;
}
.scores_games {
  background-color: #ebebeb;
  color: #252b33;
  font-family: "Futura-LT-Heavy";
  font-size: 39px;
  line-height: 57px;
  text-align: center;
}
.scores_score {
  border: 3px solid #9ea0a6;
  border-radius: 10px;
  color: #fff;
  font-family: Futura-LT-Heavy;
  font-size: 31px;
  height: 57px;
  line-height: 51px;
  right: 0px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 6.3%;
}
.scores_score:nth-child(1) {
  right: 30%;
}
.scores_score:nth-child(2) {
  right: 22.5%;
}
.scores_score:nth-child(3) {
  right: 15%;
}
.scores_score:nth-child(4) {
  right: 7.5%;
}
.scores_score:nth-child(5) {
  right: 0;
}
.scores_length {
  color: #fff;
  font-family: "Futura-LT-Heavy";
  font-size: 27px;
  line-height: 36px;
  padding-left: 3px;
  position: absolute;
  right: 37%;
  text-align: center;
  top: 0;
  width: 7.8%;
}
.scores_length_game {
  color: #fff;
  font-family: "Futura-LT-Heavy";
  font-size: 27px;
  line-height: 36px;
  padding-left: 3px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 6.3%;
}
.scores_length_game:nth-child(1) {
  right: 30%;
}
.scores_length_game:nth-child(2) {
  right: 22.5%;
}
.scores_length_game:nth-child(3) {
  right: 15%;
}
.scores_length_game:nth-child(4) {
  right: 7.5%;
}
.scores_length_game:nth-child(5) {
  right: 0;
}
.scores_decision {
  background-color: #30ad63;
  border-radius: 10px;
  left: 547px;
  position: absolute;
  text-align: center;
  width: 304px;
}
.scores_decision_1 {
  top: 58px;
}
.scores_decision_2 {
  top: 124px;
}
.scores_decision_text {
  color: #fff;
  font-family: "Futura-LT-Heavy";
  font-size: 31px;
  line-height: 33px;
}
.scores_decision {
  left: 426px;
  width: 44px;
}
.scores_decision_1 {
  top: 68px;
}
.scores_decision_2 {
  top: 134px;
}
.scores_decision_text {
  font-size: 17px;
  letter-spacing: 1px;
}
.match_best_3 .scores_score_4,
.match_best_3 .scores_score_5 {
  display: none;
}
.schedule {
  background-color: #1b1d22;
  border-radius: 20px;
  display: none;
  margin: 30px auto;
  min-width: 640px;
  padding: 12px 8px 9px;
  position: relative;
  width: 100%;
}
.schedule_fixture {
  background-color: #515561;
  border-radius: 12px;
  height: 46px;
  margin-top: 7px;
  position: relative;
}
.schedule_fixture_border {
  border: 3px solid #ebebeb;
  border-radius: 12px;
  height: 46px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.schedule_player {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  width: 50%;
}
.schedule_player_1 {
  padding-left: 96px;
  padding-right: 36px;
}
.schedule_player_2 {
  padding-left: 36px;
  padding-right: 96px;
}
.schedule_country {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 40px;
  position: absolute;
  top: 3px;
  width: 80px;
}
.schedule_country_1 {
  left: 3px;
}
.schedule_country_2 {
  right: 3px;
}
.schedule_name {
  color: #fff;
  display: inline-block;
  font-family: OswaldRegular;
  font-size: 27px;
  line-height: 46px;
  vertical-align: middle;
}
.schedule_seeding {
  color: #fff;
  display: inline-block;
  font-family: OswaldRegular;
  font-size: 27px;
  line-height: 46px;
  padding-left: 6px;
  vertical-align: middle;
}
.schedule_v_back {
  border-left-color: rgba(0, 0, 0, .25);
  border-left-style: solid;
  border-left-width: 7px;
  border-right-color: rgba(0, 0, 0, .25);
  border-right-style: solid;
  border-right-width: 7px;
  height: 46px;
  left: calc(50% - 28px);
  position: absolute;
  top: 0;
  width: 56px;
}
.schedule_v {
  background-color: #ebebeb;
  color: #252b33;
  font-family: "Futura-LT-Heavy";
  font-size: 39px;
  line-height: 40px;
  padding-bottom: 6px;
  text-align: center;
}
.results {
  text-align: center;
}
.result {
  display: none;
  font-family: OswaldRegular;
  padding: 2px 0;
}
.result_round {
  display: none;
  padding: 10px 0 4px;
}
.result:first-child .result_round {
  padding-top: 0;
}
.result_round_text {
  color: #30ad63;
  font-size: 25px;
}
.result_match {
  display: inline-block;
  vertical-align: middle;
}
.result_match_text {
  color: #fff;
  font-size: 21px;
}
.result_scores {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
.result_scores_text {
  color: #b7b7b7;
  font-size: 19px;
}
.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}
.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}
.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}
.hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(202 138 4 / var(--tw-bg-opacity));
}
@media (min-width: 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
/*# sourceMappingURL=/build/css-bundle-PC3NNTUT.css.map */
