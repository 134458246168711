@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
    font-family: 'DroidSansMono';
    src: url('/fonts/DroidSansMono/DroidSansMono.eot');
    src: local('☺'), url('/fonts/DroidSansMono/DroidSansMono.woff') format('woff'), url('/fonts/DroidSansMono/DroidSansMono.ttf') format('truetype'), url('/fonts/DroidSansMono/DroidSansMono.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura-LT-Heavy';
    src: url('/fonts/Futura-LT-Heavy/Futura-LT-Heavy.eot');
    src: local('☺'), url('/fonts/Futura-LT-Heavy/Futura-LT-Heavy.woff') format('woff'), url('/fonts/Futura-LT-Heavy/Futura-LT-Heavy.ttf') format('truetype'), url('/fonts/Futura-LT-Heavy/Futura-LT-Heavy.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OswaldRegular';
    src: url('/fonts/OswaldRegular/OswaldRegular.eot');
    src: url('/fonts/OswaldRegular/OswaldRegular.eot') format('embedded-opentype'),
        url('/fonts/OswaldRegular/OswaldRegular.woff') format('woff'),
        url('/fonts/OswaldRegular/OswaldRegular.ttf') format('truetype'),
        url('/fonts/OswaldRegular/OswaldRegular.svg#OswaldRegular') format('svg');
}

@font-face {
    font-family: 'OswaldLight';
    src: url('/fonts/OswaldLight/OswaldLight.eot');
    src: url('/fonts/OswaldLight/OswaldLight.eot') format('embedded-opentype'),
        url('/fonts/OswaldLight/OswaldLight.woff') format('woff'),
        url('/fonts/OswaldLight/OswaldLight.ttf') format('truetype'),
        url('/fonts/OswaldLight/OswaldLight.svg#OswaldLight') format('svg');
}

/* * {
    border: none;
    margin: 0;
    padding: 0;
} */

/* html {
    font-size: 125%;
    overflow-y: scroll;
} */

/* body {
    font-size: 50%;
} */

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

div {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

input[type=text],
input[type=password],
input[type=submit],
textarea,
button {
    -webkit-appearence: none;
    -moz-appearence: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
}

input[type=text]::-ms-clear {
    display: none;
}

table {
    border-collapse: collapse;
}

table thead {
    display: table-header-group;
}

table tbody {
    display: table-row-group;
}

body {
    /* background-color: #252b33; */
    padding: 25px 10px;
}

.live {
    margin: 0 auto;
    max-width: 940px;
}

.tournaments {}

.tournament {
    background-color: #1b1d22;
    border-color: transparent;
    border-radius: 20px;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
    display: none;
    margin-bottom: 25px;
    padding: 15px;
    text-align: center;
}

.tournament_selected {
    border-color: #30ad63;
}

.tournament_name {
    color: #fff;
    cursor: pointer;
    font-family: OswaldRegular;
    font-size: 39px;
    line-height: 1.3;
    text-transform: uppercase;
}

.tournament_location {
    color: #b7b7b7;
    cursor: pointer;
    font-family: OswaldRegular;
    font-size: 25px;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
}

.tournament_dates {
    color: #7c7c7c;
    cursor: pointer;
    font-family: OswaldLight;
    font-size: 19px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
}

.tournament_time {
    background-color: #000;
    border-radius: 10px;
    display: none;
    margin: 0 auto;
    padding: 2px 0 10px;
    text-align: center;
    width: 144px;
}

.tournament_time_time {
    color: #fff;
    font-family: DroidSansMono;
    font-size: 31px;
    letter-spacing: -3px;
    line-height: 1.4;
}

.tournament_time_label {
    color: #30ad63;
    display: inline-block;
    font-family: OswaldRegular;
    font-size: 15px;
    line-height: 1;
    padding-left: 5px;
    text-transform: uppercase;
}

.matches {
    padding: 0 5px;
    text-align: center;
}

.match {}

.match_info {
    background-color: #101011;
    border-radius: 8px;
    position: relative;
    text-align: left;
}

.match_round {
    color: #fff;
    display: inline-block;
    font-family: OswaldRegular;
    font-size: 25px;
    line-height: 36px;
    margin-left: 1.2%;
    vertical-align: middle;
}

.match_court {
    color: #b7b7b7;
    display: inline-block;
    font-family: OswaldRegular;
    font-size: 21px;
    line-height: 36px;
    vertical-align: middle;
    margin-left: 1.2%;
}

.match_status {
    color: #30ad63;
    display: inline-block;
    font-family: OswaldRegular;
    font-size: 21px;
    line-height: 36px;
    vertical-align: middle;
    margin-left: 1.2%;
}

.scores {
    background-color: #1b1d22;
    border-radius: 20px;
    display: block;
    margin: 30px auto;
    min-width: 640px;
    padding: 12px 8px;
    position: relative;
    width: 100%;
}

.scores_player {
    background-color: #515561;
    border-radius: 12px;
    height: 57px;
    position: relative;
}

.scores_player_1 {
    margin-top: 8px;
}

.scores_player_2 {
    margin-top: 9px;
}

.scores_player_border {
    border: 3px solid #ebebeb;
    border-radius: 12px;
    height: 57px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.scores_country {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 40px;
    left: 11px;
    position: absolute;
    top: 9px;
    width: 80px;
}

.scores_player_info {
    padding-left: 104px;
    position: absolute;
    text-align: left;
    top: 0;
    white-space: nowrap;
    width: 54%;
}

.scores_name {
    color: #fff;
    display: inline-block;
    font-family: OswaldRegular;
    font-size: 30px;
    text-transform: uppercase;
    line-height: 57px;
    vertical-align: middle;
}

.scores_seeding {
    display: inline-block;
    color: #fff;
    display: inline-block;
    font-family: OswaldRegular;
    font-size: 30px;
    line-height: 57px;
    padding-left: 8px;
    vertical-align: middle;
}

.scores_games_back {
    border-left-color: rgba(0, 0, 0, .25);
    border-left-style: solid;
    border-left-width: 7px;
    border-right-color: rgba(0, 0, 0, .25);
    border-right-style: solid;
    border-right-width: 7px;
    height: 57px;
    right: 37%;
    position: absolute;
    top: 0;
    width: 7.8%;
}

.scores_games {
    background-color: #ebebeb;
    color: #252b33;
    font-family: 'Futura-LT-Heavy';
    font-size: 39px;
    line-height: 57px;
    text-align: center;
}

.scores_score {
    border: 3px solid #9ea0a6;
    border-radius: 10px;
    color: #fff;
    font-family: Futura-LT-Heavy;
    font-size: 31px;
    height: 57px;
    line-height: 51px;
    right: 0px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 6.3%;
}

.scores_score:nth-child(1) {
    right: 30%;
}

.scores_score:nth-child(2) {
    right: 22.5%;
}

.scores_score:nth-child(3) {
    right: 15%;
}

.scores_score:nth-child(4) {
    right: 7.5%;
}

.scores_score:nth-child(5) {
    right: 0;
}

.scores_length {
    color: #fff;
    font-family: 'Futura-LT-Heavy';
    font-size: 27px;
    line-height: 36px;
    padding-left: 3px;
    position: absolute;
    right: 37%;
    text-align: center;
    top: 0;
    width: 7.8%;
}

.scores_length_game {
    color: #fff;
    font-family: 'Futura-LT-Heavy';
    font-size: 27px;
    line-height: 36px;
    padding-left: 3px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 6.3%;
}

.scores_length_game:nth-child(1) {
    right: 30%;
}

.scores_length_game:nth-child(2) {
    right: 22.5%;
}

.scores_length_game:nth-child(3) {
    right: 15%;
}

.scores_length_game:nth-child(4) {
    right: 7.5%;
}

.scores_length_game:nth-child(5) {
    right: 0;
}

.scores_decision {
    background-color: #30ad63;
    border-radius: 10px;
    left: 547px;
    position: absolute;
    text-align: center;
    width: 304px;
}

.scores_decision_1 {
    top: 58px;
}

.scores_decision_2 {
    top: 124px;
}

.scores_decision_text {
    color: #fff;
    font-family: 'Futura-LT-Heavy';
    font-size: 31px;
    line-height: 33px;
}

.scores_decision {
    left: 426px;
    width: 44px;
}

.scores_decision_1 {
    top: 68px;
}

.scores_decision_2 {
    top: 134px;
}

.scores_decision_text {
    font-size: 17px;
    letter-spacing: 1px;
}

.match_best_3 .scores_score_4,
.match_best_3 .scores_score_5 {
    display: none;
}

.schedule {
    background-color: #1b1d22;
    border-radius: 20px;
    display: none;
    margin: 30px auto;
    min-width: 640px;
    padding: 12px 8px 9px;
    position: relative;
    width: 100%;
}

.schedule_fixture {
    background-color: #515561;
    border-radius: 12px;
    height: 46px;
    margin-top: 7px;
    position: relative;
}

.schedule_fixture_border {
    border: 3px solid #ebebeb;
    border-radius: 12px;
    height: 46px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.schedule_player {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    width: 50%;
}

.schedule_player_1 {
    padding-left: 96px;
    padding-right: 36px;
}

.schedule_player_2 {
    padding-left: 36px;
    padding-right: 96px;
}

.schedule_country {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 40px;
    position: absolute;
    top: 3px;
    width: 80px;
}

.schedule_country_1 {
    left: 3px;
}

.schedule_country_2 {
    right: 3px;
}

.schedule_name {
    color: #fff;
    display: inline-block;
    font-family: OswaldRegular;
    font-size: 27px;
    line-height: 46px;
    vertical-align: middle;
}

.schedule_seeding {
    color: #fff;
    display: inline-block;
    font-family: OswaldRegular;
    font-size: 27px;
    line-height: 46px;
    padding-left: 6px;
    vertical-align: middle;
}

.schedule_v_back {
    border-left-color: rgba(0, 0, 0, .25);
    border-left-style: solid;
    border-left-width: 7px;
    border-right-color: rgba(0, 0, 0, .25);
    border-right-style: solid;
    border-right-width: 7px;
    height: 46px;
    left: calc(50% - 28px);
    position: absolute;
    top: 0;
    width: 56px;
}

.schedule_v {
    background-color: #ebebeb;
    color: #252b33;
    font-family: 'Futura-LT-Heavy';
    font-size: 39px;
    line-height: 40px;
    padding-bottom: 6px;
    text-align: center;
}

.results {
    text-align: center;
}

.result {
    display: none;
    font-family: OswaldRegular;
    padding: 2px 0;
}

.result_round {
    display: none;
    padding: 10px 0 4px;
}

.result:first-child .result_round {
    padding-top: 0;
}

.result_round_text {
    color: #30ad63;
    font-size: 25px;
}

.result_match {
    display: inline-block;
    vertical-align: middle;
}

.result_match_text {
    color: #fff;
    font-size: 21px;
}

.result_scores {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.result_scores_text {
    color: #b7b7b7;
    font-size: 19px;
}